// src/components/Layout/Header.js
import React, { useContext } from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import { LightMode, DarkMode } from '@mui/icons-material'; // Updated imports
import { ThemeContext } from '../../context/ThemeContext';

const Header = () => {
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);

  return (
    <AppBar position="static" color="primary" enableColorOnDark>
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Research Companion
        </Typography>
        <IconButton color="inherit" onClick={toggleTheme}>
          {isDarkMode ? <LightMode /> : <DarkMode />}
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
