// src/components/Dashboard/Dashboard.js

import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

// Import your IconCard component
import IconCard from './IconCard';
import './Dashboard.css'; // For additional styling

// Import MUI Icons
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import EditIcon from '@mui/icons-material/Edit';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TranslateIcon from '@mui/icons-material/Translate';
import AssessmentIcon from '@mui/icons-material/Assessment';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DescriptionIcon from '@mui/icons-material/Description';
import InsightsIcon from '@mui/icons-material/Insights';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import RateReviewIcon from '@mui/icons-material/RateReview';  // For Review Manuscript
import ArticleIcon from '@mui/icons-material/Article';        // For Draft Proposal

const Dashboard = () => {
  // ====================
  // 1) SEARCH FUNCTIONALITY
  // ====================
  const [searchTerm, setSearchTerm] = useState('');

  // 2) Modules Array
  const modules = [
    {
      label: 'Literature Review',
      icon: <AutoStoriesIcon fontSize="large" color="primary" />,
      link: '/literature-review',
      description: 'Conduct a comprehensive literature review on a given topic.',
    },
    {
      label: 'Find Research Gaps',
      icon: <FindInPageIcon fontSize="large" color="primary" />,
      link: '/find-research-gaps',
      description: 'Identify unexplored or underexplored areas in existing research.',
    },
    {
      label: 'Summarize Content',
      icon: <SummarizeIcon fontSize="large" color="primary" />,
      link: '/summarize-content',
      description: 'Generate concise summaries of large texts or documents.',
    },
    {
      label: 'Proofread & Polish',
      icon: <SpellcheckIcon fontSize="large" color="primary" />,
      link: '/proofread-polish',
      description: 'Improve grammar, spelling, and style of your text.',
    },
    {
      label: 'Revise & Improve',
      icon: <EditIcon fontSize="large" color="primary" />,
      link: '/revise-improve',
      description: 'Enhance clarity, depth, and overall quality of your text.',
    },
    {
      label: 'Explain Content',
      icon: <HelpOutlineIcon fontSize="large" color="primary" />,
      link: '/explain-content',
      description: 'Elaborate on complex content in simpler or more detailed terms.',
    },
    {
      label: 'Translate Content',
      icon: <TranslateIcon fontSize="large" color="primary" />,
      link: '/translate-content',
      description: 'Convert text from one language to another accurately.',
    },
    {
      label: 'Evaluate Methodology',
      icon: <AssessmentIcon fontSize="large" color="primary" />,
      link: '/evaluate-methodology',
      description: 'Critically analyze and review research methodologies.',
    },
    {
      label: 'Suggest Titles',
      icon: <EmojiObjectsIcon fontSize="large" color="primary" />,
      link: '/suggest-titles',
      description: 'Generate creative and relevant titles for academic papers.',
    },
    {
      label: 'Suggest Outline',
      icon: <ListAltIcon fontSize="large" color="primary" />,
      link: '/suggest-outline',
      description: 'Develop structured outlines for your writing projects.',
    },
    {
      label: 'Draft Abstract',
      icon: <DescriptionIcon fontSize="large" color="primary" />,
      link: '/draft-abstract',
      description: 'Create concise abstracts summarizing your research.',
    },
    {
      label: 'Interpret Results',
      icon: <InsightsIcon fontSize="large" color="primary" />,
      link: '/interpret-results',
      description: 'Provide an in-depth interpretation of research findings.',
    },
    {
      label: 'Draft Manuscript',
      icon: <EditAttributesIcon fontSize="large" color="primary" />,
      link: '/draft-manuscript',
      description: 'Compose a complete manuscript integrating all sections seamlessly.',
    },
    // New Modules Added
    {
      label: 'Review Manuscript',
      icon: <RateReviewIcon fontSize="large" color="primary" />,
      link: '/review-manuscript',
      description: 'Provide detailed feedback on a manuscript draft.',
    },
    {
      label: 'Draft Proposal',
      icon: <ArticleIcon fontSize="large" color="primary" />,
      link: '/draft-proposal',
      description: 'Compose a comprehensive research proposal from scratch.',
    },
  ];

  // 3) Filter modules based on search term
  const filteredModules = modules.filter((module) =>
    module.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box className="dashboard-container">
      {/* ====================
        Welcome / Introductory Section
      ==================== */}
      <Typography variant="h4" className="dashboard-title" gutterBottom>
        Research Toolbox
      </Typography>
      <Typography variant="body1" className="dashboard-intro">
        Streamline the mechanics of research, enabling you to dedicate more time to groundbreaking discoveries.
      </Typography>

      {/* Search or Filter Functionality */}
      <Box className="search-bar-container">
        <TextField
          variant="outlined"
          placeholder="Search modules..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          size="small"
          className="search-field"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {/* Scrollable Modules Container */}
      <Box className="modules-container">
        <Grid container spacing={4} justifyContent="center">
          {filteredModules.map((module) => (
            <Grid item key={module.label} xs={12} sm={6} md={4} lg={3}>
              <Tooltip title={module.description} arrow placement="top">
                <span>
                  <IconCard label={module.label} icon={module.icon} link={module.link} />
                </span>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Dashboard;
