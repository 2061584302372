// src/routes/AppRouter.js

import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from '../components/Dashboard/Dashboard';

// Lazy load modules for performance optimization
const LiteratureReview = lazy(() => import('../components/Modules/LiteratureReview/LiteratureReview'));
const FindResearchGaps = lazy(() => import('../components/Modules/FindResearchGaps/FindResearchGaps'));

// The following modules are currently commented out for development purposes.
// Uncomment them once the respective modules are set up and ready for integration.

const SummarizeContent = lazy(() => import('../components/Modules/SummarizeContent/SummarizeContent'));
const ProofreadAndPolish = lazy(() => import('../components/Modules/ProofreadAndPolish/ProofreadAndPolish'));
// const ReviseImprove = lazy(() => import('../components/Modules/ReviseImprove/ReviseImprove'));
const ExplainContent = lazy(() => import('../components/Modules/ExplainContent/ExplainContent'));
// const TranslateContent = lazy(() => import('../components/Modules/TranslateContent/TranslateContent'));
const EvaluateMethodology = lazy(() => import('../components/Modules/EvaluateMethodology/EvaluateMethodology'));
// const SuggestTitles = lazy(() => import('../components/Modules/SuggestTitles/SuggestTitles'));
const SuggestOutline = lazy(() => import('../components/Modules/SuggestOutline/SuggestOutline'));
// const DraftAbstract = lazy(() => import('../components/Modules/DraftAbstract/DraftAbstract'));
// const DraftIntroduction = lazy(() => import('../components/Modules/DraftIntroduction/DraftIntroduction'));
const DraftManuscript = lazy(() => import('../components/Modules/DraftManuscript/DraftManuscript'));
const ReviewManuscript = lazy(() => import('../components/Modules/ReviewManuscript/ReviewManuscript'));
const InterpretResults = lazy(() => import('../components/Modules/InterpretResults/InterpretResults'));

const AppRouter = () => {
  return (
    <Router>
      {/* Suspense provides a fallback while lazy-loaded components are being fetched */}
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {/* Dashboard Route */}
          <Route path="/" element={<Dashboard />} />

          {/* Active Module Routes */}
          <Route path="/literature-review" element={<LiteratureReview />} />
          <Route path="/find-research-gaps" element={<FindResearchGaps />} />
		  <Route path="/proofread-polish" element={<ProofreadAndPolish />} />
		  <Route path="/summarize-content" element={<SummarizeContent />} />
		  <Route path="/explain-content" element={<ExplainContent />} />
		  <Route path="/suggest-outline" element={<SuggestOutline />} />
		  <Route path="/interpret-results" element={<InterpretResults />} />
		  <Route path="/draft-manuscript" element={<DraftManuscript />} />
		  <Route path="/review-manuscript" element={<ReviewManuscript />} />
		  <Route path="/evaluate-methodology" element={<EvaluateMethodology />} />

          {/* Inactive Module Routes - Uncomment when ready */}
          {/* 
          <Route path="/summarize-content" element={<SummarizeContent />} />
          <Route path="/proofread-polish" element={<ProofreadPolish />} />
          <Route path="/revise-improve" element={<ReviseImprove />} />
          <Route path="/explain-content" element={<ExplainContent />} />
          <Route path="/translate-content" element={<TranslateContent />} />
          <Route path="/evaluate-methodology" element={<EvaluateMethodology />} />
          <Route path="/suggest-titles" element={<SuggestTitles />} />
          <Route path="/suggest-outline" element={<SuggestOutline />} />
          <Route path="/draft-abstract" element={<DraftAbstract />} />
          <Route path="/draft-introduction" element={<DraftIntroduction />} />
          <Route path="/draft-materials-methods" element={<DraftMaterialsMethods />} />
          <Route path="/draft-results-discussion" element={<DraftResultsDiscussion />} />
          <Route path="/interpret-results" element={<InterpretResults />} />
          */}

          {/* Catch-all Route for Undefined Paths (404 Not Found) */}
          <Route path="*" element={<div>404 - Page Not Found</div>} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default AppRouter;
