// src/components/App.js
import React from 'react';
import { Box } from '@mui/material';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import AppRouter from '../routes/AppRouter';

const App = () => {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Header />
      <Box flex="1">
        <AppRouter />
      </Box>
      <Footer />
    </Box>
  );
};

export default App;
