// src/components/Dashboard/IconCard.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardActionArea, CardContent, Typography, Box } from '@mui/material';

const IconCard = ({ label, icon, link }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(link);
  };

  return (
    <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
      <CardActionArea onClick={handleClick} sx={{ textAlign: 'center', padding: 2 }}>
        <CardContent>
          <Box display="flex" justifyContent="center" mb={2}>
            {icon}
          </Box>
          <Typography variant="h6">{label}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default IconCard;
